
export const separateNumberWithComma = (myNumber) => {

    if (!myNumber && myNumber !== 0) return

    return myNumber
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getFileTypeByUrl = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    
    if (['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) {
        return 'image';
    } else if (['doc', 'docx'].includes(extension)) {
        return 'word';
    } else if (['zip', 'rar', '7z'].includes(extension)) {
        return 'zip';
    } else {
        return 'unknown';
    }
}

export function groupBy(arr, getKey) {
    return arr.reduce((acc, item) => {
        const key = getKey(item);
        acc[key] = acc[key] || [];
        acc[key].push(item);
        return acc;
    }, {});
}

export const convertMyPackageIdToNormal = (myId) => {
    return myId?.replace("_p","");
}

export const handleGenerateLink = (link) => {

    // reportage?.news?.site || new URL(reportage?.news?.site || "").hostname?.split("www.")[1] || new URL(reportage?.news?.site).hostname || ""

    if (link) {
        return new URL(link || "").hostname?.split("www.")[1] || new URL(link).hostname
    }

    return ""

}

export const addHttpToLink = (link = "") => {
    if (link?.search("https://") > -1 || link?.search("http://") > -1) {
        return link
    }

    return "https://" + link
}