/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import StepPattern from "../../../components/stepPattern";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { routes } from "../../../routes";
import SiteAlert from "../../../components/alert";
import InputTemplate from "../../../components/formComponents/inputComponent";
import { Helmet } from "react-helmet-async";
import TagsInput from "react-tagsinput";
import RadioButtonTemplate from "../../../components/formComponents/radioButtonComponent";
import { getSingleCampaign } from "../../../service/campaign";
import { createUserReportage, getUserReportageِDetail, updateUserReportage, uploadReporatgeFile } from "../../../service/reportage";
// import DatePicker from "react-multi-date-picker";
import DatePickerComponent from "../../../components/datePicker";
import { toast } from "react-toastify";
import jMoment from "moment-jalaali";
import $ from "jquery"
import { LoadingData } from "../../../components/loading";
import LoadingButton from "../../../components/loadingButton";
import SiteModal from "../../../components/siteModal";
import ReportageDetailModal from "../../../components/reportage/detailModal";
import NoDataComponent from "../../../components/noData";
import { groupBy, handleGenerateLink } from "../../../utilities";
import { storageUrl } from "../../../utilities/config";


const CreateReportagePage = () => {

    const { id } = useParams();

    const [searchParams] = useSearchParams();

    const editMode = searchParams.get("edit");

    const navigate = useNavigate();

    const DISABLE_REPORTAGE = [
        "منتشر شده",
        "لغو شده",
        "در انظار انتشار",
        "در حال بررسی",
    ]

    const STEP_ARRAY = [
        {
            title: "انتخاب وبسایت",
            status: "current"
        },
        {
            title: "بارگذاری فایل رپورتاژ",
            status: "none"
        },
        {
            title: "پردازش فایل",
            status: "none"
        },
        {
            title: "زمان انتشار",
            status: "none"
        },
    ]

    const [StepArray, setStepArray] = useState(STEP_ARRAY);
    const [CampaignReport, setCampaignReport] = useState({});
    const [CampaignLoading, setCampaignLoading] = useState(true);
    const [CreateLoading, setCreateLoading] = useState(false);
    const [PublishData, setPublishData] = useState({
        titleFile: "",
        selectedReportage: -1,
        selectedReportageObj: {},
        reportageFile: null,
        // reportageFileResults: {},
        publishTime: new Date(),
        tag: [],
        reportageAttachFile: null,
        description: "",
    })
    const [ReportageResults, setReportageResults] = useState({
        loading: false,
        loadingProgress: 0,
        data: {},
    })
    const [ShowReportageModal, setShowReportageModal] = useState({
        show: false,
        selectedId: -1,
        selectedReportage: {}
    });
    const [ReportageSingleData, setReportageSingleData] = useState({
        loading: false,
        data: {},
    })


    const handleRemoveUploadedReportageFile = (e) => {
        e.preventDefault();
        setPublishData({ ...PublishData, reportageFile: null })
        setReportageResults({ data: {}, loading: false });
    }

    const handleRemoveUploadedReportageAttachFile = (e) => {
        e.preventDefault();
        setPublishData({ ...PublishData, reportageAttachFile: null })
    }

    const handleDrop = (e, stateName) => {
        e.preventDefault();

        if (ReportageResults.loading) {
            return
        }

        const file = e.dataTransfer.files;
        if (stateName === "reportageFile") {
            setPublishData({ ...PublishData, reportageFile: file })
            handleUploadFile()
        } else if (stateName === "reportageAttachFile") {
            setPublishData({ ...PublishData, reportageAttachFile: file })
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleGetCampaignReportage = () => {
        getSingleCampaign(id).then(({ data }) => {
            const seletedReportageId = searchParams.get("reportage");

            console.log({
                group: groupBy(data?.reportage, ({ reportage }) => reportage?.id)
            })

            let helpRes = {
                ...data,
                groupReportage: groupBy(data?.reportage, ({ reportage }) => reportage?.id)
            }

            setCampaignReport(helpRes)
            setCampaignLoading(false);

            const selectedItem = data?.reportage?.find(item => item?.id === parseInt(seletedReportageId));

            let helpData = {
                selectedReportage: parseInt(seletedReportageId) || -1,
                selectedReportageObj: selectedItem || {},
                titleFile: "",
                reportageFile: null,
                // reportageFileResults: {},
                publishTime: new Date(),
                tag: [],
                reportageAttachFile: null,
                description: "",
            }

            if (editMode) {
                // helpData.reportageFile = selectedItem.file;
                // if (selectedItem.attach_file) {
                //     if ((selectedItem.attach_file || "").replace(storageUrl, "") !== "") {
                //         helpData.reportageAttachFile = selectedItem.attach_file;
                //     }
                // }
                // setReportageResults({
                //     ...ReportageResults,
                //     data : JSON.parse(selectedItem?.details)
                // })
                helpData.tag = JSON.parse(selectedItem.tags || "[]");
                helpData.publishTime = new Date(selectedItem.publish_at);
                helpData.description = selectedItem.description;
                helpData.titleFile = selectedItem.title;
            }

            console.log({ helpData })

            setPublishData(helpData)
        }).catch((err) => {
            console.log(err)
        })
    }

    // const handleGetReportageDetail = () => {
    //     getUserReportageِDetail(id).then(({ data }) => {
    //         setCampaignReport(data)
    //     }).catch((err) => {
    //         console.log(err)
    //     })
    // }

    const handleGetOneReportage = (reportageId) => {

        console.log(reportageId)

        if (reportageId === -1 || ReportageSingleData.loading || !ShowReportageModal.show) return

        setReportageSingleData({
            ...ReportageSingleData,
            loading: true,
        })
        getUserReportageِDetail(reportageId).then(({ data }) => {
            setReportageSingleData({
                loading: false,
                data: data
            })
        }).catch((err) => {
            setReportageSingleData({
                loading: false,
                data: {}
            })
            console.log(err);
        })
    }

    const handleCreateReportage = () => {

        if (PublishData.selectedReportage === -1) {
            toast.error("یک رپورتاژ را انتخاب کنید!");
            $('html, body').animate({
                scrollTop: $("#step1").offset().top - 50
            }, 400);
            return
        }

        if (PublishData.reportageFile === null) {
            toast.error("فایل رپورتاژ مورد نظر را آپلود کنید!");
            $('html, body').animate({
                scrollTop: $("#step2").offset().top - 50
            }, 10);
            return
        }

        if (PublishData.publishTime === "") {
            toast.error("زمان پیشنهادی خود را تعیین کنید!");
            $('html, body').animate({
                scrollTop: $("#step4").offset().top - 50
            }, 10);
            return
        }
        if (PublishData.titleFile.trim() === "") {
            toast.error("عنوان فایل خود را وارد کنید!");
            $('html, body').animate({
                scrollTop: $("#step3").offset().top - 50
            }, 10);
            return
        }
        // if (PublishData.selectedReportageObj?.reportage?.info?.max_word < ReportageResults.data?.wordCount) {
        //     toast.error("تعداد کلمات فایل انتخابی بیشتر از حد مجاز است!");
        //     $('html, body').animate({
        //         scrollTop: $("#step3").offset().top - 50
        //     }, 10);
        //     return
        // }
        // if (PublishData.tag.length === 0) {
        //     toast.error("تگ های رپورتاژ خود را وارد کنید!");
        //     $('html, body').animate({
        //         scrollTop: $("#step5").offset().top - 50
        //     }, 10);
        //     return
        // }


        let formData = new FormData();
        formData.append("reportage_client_id", PublishData.selectedReportage);
        if(PublishData.description?.trim() !== ""){
            formData.append("description", PublishData.description);
        }

        if (typeof PublishData.reportageFile !== 'string') {
            formData.append("file", PublishData.reportageFile[0]);
        }

        formData.append("details", JSON.stringify(ReportageResults.data));
        formData.append("publish_at", jMoment(new Date(PublishData.publishTime)).format("YYYY-MM-DD"));

        for (let i = 0; i < PublishData.tag.length; i++) {
            const element = PublishData.tag[i];
            formData.append("tags[]", element);

        }

        if (PublishData.titleFile.trim() !== "") {
            formData.append("title", PublishData.titleFile);
        }

        if (PublishData.reportageAttachFile && typeof PublishData.reportageAttachFile !== 'string') {
            formData.append("attach_file", PublishData.reportageAttachFile[0]);
        }

        setCreateLoading(true);

        const seletedReportageId = searchParams.get("reportage");


        if (editMode && parseInt(PublishData.selectedReportage) === parseInt(seletedReportageId || "")) {
            formData.append("_method", "put");
            updateUserReportage(PublishData.selectedReportage, formData).then(({ data }) => {
                toast.success("با موفقیت ثبت شد")
                setCreateLoading(false);
                navigate(routes.reportage.myReportage)
            }).catch((err) => {
                console.log(err);
                setCreateLoading(false);
            })
        } else {
            createUserReportage(formData).then(({ data }) => {
                toast.success("با موفقیت ثبت شد")
                setCreateLoading(false);
                navigate(routes.reportage.myReportage)
            }).catch((err) => {
                console.log(err);
                setCreateLoading(false);
            })
        }


        // console.log(formData);
    }

    const handleAddReportageFile = (file) => {

        if (ReportageResults.loading) {
            return
        }

        setPublishData({ ...PublishData, reportageFile: file })
        // handleUploadFile();
    }

    const handleUploadFile = () => {

        const formData = new FormData();

        formData.append("file", PublishData.reportageFile[0]);

        setReportageResults({ data: {}, loading: true });
        const inter = setInterval(() => {
            setReportageResults(prevCounter => prevCounter.loadingProgress + 1);
        }, 1000)
        uploadReporatgeFile(formData).then(({ data }) => {
            clearInterval(inter);
            setReportageResults({ data: data, loading: false });
            setPublishData({
                ...PublishData ,
                titleFile : data?.title
            })
            console.log(data)
        }).catch((err) => {
            clearInterval(inter);
            setReportageResults({ ...ReportageResults, loading: false });
            console.log(err)
        })
    }

    const handleCloseModal = () => {
        setShowReportageModal({
            show: false,
            selectedId: -1,
            selectedReportage: {}
        })
    }

    const handleOpenDatePicker = () => {
        $(".datePickerInput").focus();
    }

    const handleGoBack = () => {
        navigate(-1)
    }

    useEffect(() => {
        handleGetCampaignReportage();
    }, [id])

    useEffect(() => {
        if (PublishData.reportageFile && typeof PublishData.reportageFile !== 'string') {
            handleUploadFile();
        }
    }, [PublishData.reportageFile])

    useEffect(() => {
        handleGetOneReportage(ShowReportageModal.selectedId);
    }, [ShowReportageModal.show])



    return (
        <>
            <Helmet>
                <title>انتشار رپورتاژ</title>
            </Helmet>

            <SiteModal
                modalDesign={2}
                title={"رپورتاژ خبر"}
                showModal={ShowReportageModal.show}
                closeModal={handleCloseModal}
            >

                {/* {ReportageSingleData.loading ?

                    <>
                        <div className="d-flex align-items-center justify-content-center my-3">
                            <LoadingData />
                        </div>
                    </>
                    :
                    <>
                    </>
                } */}
                <ReportageDetailModal
                    reportageData={ShowReportageModal.selectedReportage?.reportage}
                    justLaw={true}
                />


            </SiteModal>

            <div className="container">
                <div className="headerCreateReportageMainBox">

                    <div className="siteTempTitle">{editMode ? "ویرایش" : "ساخت"} رپورتاژ</div>

                    <StepPattern stepArray={StepArray} />

                    {/* <Link to={routes.campaign.create}> */}
                    <div className="siteTempBtn headerCreateReportageBack"
                        onClick={handleGoBack}
                    >

                        <div>بازگشت</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                            <path d="M25.9997 25.6773L3.21484 3" stroke="#28323D" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M25.7848 3.32263L3 25.9999" stroke="#28323D" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </div>
                    {/* </Link> */}
                </div>


                <div className="customBox pb-5">

                    <div className="createReportageBodyMainBox">


                        <div className="createReportageBodyHeadBox">
                            <div className="siteTempTitle">
                                وبسایت مورد نظر خود را انتخاب کنید
                            </div>
                            <div className="createReportageBodyCampaignTitle">
                                {CampaignReport?.name}
                            </div>
                        </div>

                        <div id="step1" className="createReportageItemBox">
                            <div className="createReportageItemStepBox">
                                <div>مرحله</div>
                                <div className="step">01</div>
                            </div>
                            <div className="createReportageItemCard">

                                {CampaignLoading ?
                                    <>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <LoadingData />
                                        </div>
                                    </>
                                    :
                                    <>
                                        {/* groupReportage */}
                                        {Object.keys(CampaignReport?.groupReportage).map((key, index) =>{

                                            const itemLeft = CampaignReport?.groupReportage[key]?.filter(item => DISABLE_REPORTAGE.indexOf(item?.status_name) === -1);
                                            let reportage = CampaignReport?.groupReportage[key][0];
                                            
                                            if(itemLeft?.length > 0){
                                                reportage = itemLeft[0];
                                            }
                                            
                                            if(searchParams.get("reportage")){
                                                reportage = CampaignReport?.groupReportage[key]?.find(item => item?.id == searchParams.get("reportage"));
                                            }

                                            // console.log({reportage})

                                            return(
                                                <>
                                                    <div
                                                        // className={"showCartListCard showOnCreateCampaign" + ((DISABLE_REPORTAGE.indexOf(reportage?.status_name) > -1) ? " disable" : "")}
                                                        className={"showCartListCard showOnCreateCampaign" + ((itemLeft?.length === 0) ? " disable" : "")}
                                                    >
                                                        <RadioButtonTemplate
                                                            name="reportageItem"
                                                            checked={parseInt(reportage?.id) === parseInt(PublishData.selectedReportage)}
                                                            value={reportage?.id}
                                                            onChange={e => { setPublishData({ ...PublishData, selectedReportage: e.target.value, selectedReportageObj: reportage }); }}
                                                        >
                                                            <div className="d-flex align-items-center justify-content-between">

                                                                <div className="showCartListCardHeader">
                                                                    <div className="showCartListCardImage">
                                                                        <img src={reportage?.reportage?.news?.image || "/assets/images/alef.png"} alt="" />
                                                                    </div>
                                                                    <div className="showCartListCardTitle">
                                                                        {reportage?.reportage?.news?.name}
                                                                    </div>
                                                                    <div className="showCartListCardLink">
                                                                        {handleGenerateLink(reportage?.reportage?.news?.site)}
                                                                    </div>
                                                                </div>

                                                                <div className="showCartListCardBody">
                                                                    <div className="reportageCreateListCount">
                                                                        {/* <div className="text-accentColor">{index === 2 ? 0 : 3}</div> */}
                                                                        {/* <div className="text-accentColor">{(reportage?.file || reportage?.status_name === "منتشر شده") ? "0" : CampaignReport?.groupReportage[key]?.length}</div> */}
                                                                        <div className="text-accentColor">{itemLeft?.length}</div>
                                                                        <div className="text-gray3">باقی مانده</div>
                                                                    </div>

                                                                    {reportage?.status_name === "منتشر شده" ?
                                                                        <>
                                                                            <div className="publishReportageContentInList">
                                                                                منتشر شده است
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="showCartListCardShortDescBox">
                                                                                <div className="showCartListCardShortDescCard">
                                                                                    <div className="showCartListCardShortDescCardImage">
                                                                                        <img src="/assets/images/image.svg" alt="" />
                                                                                    </div>
                                                                                    <div className="showCartListCardShortDescCardBody">
                                                                                        <div className="showCartListCardShortDescCardTitle">تصاویر</div>
                                                                                        <div className="showCartListCardShortDescCardValue">{reportage?.reportage?.info?.image_count}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="showCartListCardShortDescCard">
                                                                                    <div className="showCartListCardShortDescCardImage">
                                                                                        <img src="/assets/images/editWithBox.svg" alt="" />
                                                                                    </div>
                                                                                    <div className="showCartListCardShortDescCardBody">
                                                                                        <div className="showCartListCardShortDescCardTitle">کلمات</div>
                                                                                        <div className="showCartListCardShortDescCardValue">{reportage?.reportage?.info?.max_word}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="showCartListCardShortDescCard">
                                                                                    <div className="showCartListCardShortDescCardImage">
                                                                                        <img src="/assets/images/followLink.svg" alt="" />
                                                                                    </div>
                                                                                    <div className="showCartListCardShortDescCardBody">
                                                                                        <div className="showCartListCardShortDescCardTitle">لینک </div>
                                                                                        <div className="showCartListCardShortDescCardValue">{reportage?.reportage?.info?.follow_link_count}</div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="showCartListCardShortDescCard">
                                                                                    <div className="showCartListCardShortDescCardImage">
                                                                                        <img src="/assets/images/noFollow.svg" alt="" />
                                                                                    </div>
                                                                                    <div className="showCartListCardShortDescCardBody">
                                                                                        <div className="showCartListCardShortDescCardTitle">لینک نوفالو</div>
                                                                                        <div className="showCartListCardShortDescCardValue">1</div>
                                                                                    </div>
                                                                                </div> */}
                                                                            </div>
                                                                        </>

                                                                    }


                                                                    <div className="siteTempBtn design1"
                                                                        onClick={() => setShowReportageModal({ show: true, selectedId: reportage?.id, selectedReportage: reportage })}
                                                                    >
                                                                        <img src="/assets/images/info-square-outline.svg" alt="" />
                                                                        <div>قوانین رسانه</div>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                        </RadioButtonTemplate>
                                                    </div>
                                                    
                                                </>
                                            )
                                        })}
                                        {/* {CampaignReport?.reportage?.map((reportage, index) =>

                                            <>
                                            </>

                                        )} */}

                                        {CampaignReport?.reportage?.length === 0 &&

                                            <>
                                                <NoDataComponent text={"رپورتاژی یافت نشد !"} />
                                            </>

                                        }

                                    </>
                                }


                            </div>
                        </div>

                        <div id="step2" className="createReportageItemBox">
                            <div className="createReportageItemStepBox">
                                <div>مرحله</div>
                                <div className="step">02</div>
                            </div>
                            <div className="createReportageItemCard uploadReportageFile">

                                <div className="uploadReportageFileRightBox">
                                    <div className="text-blueColor fw-bold">
                                        فایل رپورتاژ آگهی
                                    </div>

                                    <div className="text-gray3 mt-3">
                                        فرمت Word , Html
                                    </div>

                                    <div className="d-flex gap-2 mt-3">
                                        <div className="siteTempBtn blueBg noHover">
                                            <img src="/assets/images/edit1.svg" alt="" />
                                            <div>سفارش تولید محتوا</div>
                                        </div>
                                        <div className="siteTempBtn design3">
                                            فایل نمونه رپورتاژ
                                        </div>
                                    </div>


                                </div>
                                <div className="uploadReportageFileLeftBox">
                                    {/* <div className="loadingResultFile">

                                    </div> */}
                                    <label className="uploadReceiptInCartBox withoutMaxWidth"
                                        onDrop={(e) => handleDrop(e, "reportageFile")}
                                        onDragOver={handleDragOver}
                                    >
                                        {PublishData.reportageFile === null ?
                                            <>

                                                <img src="/assets/images/paper-upload.svg" alt="" />
                                                <div className="uploadReceiptInCartHeader">
                                                    <div className="uploadReceiptInCartDesc">
                                                        برای آپلود فایل رپورتاژ کلیک کنید
                                                        یا فایل را در اینجا رها کنید
                                                    </div>
                                                </div>
                                                <input type="file" hidden accept=".doc , .docx"
                                                    onChange={e => handleAddReportageFile(e.target.files)}
                                                />
                                            </>
                                            :
                                            <>

                                                {ReportageResults.loading ?
                                                    <>
                                                        <img src="/assets/images/paper-upload.svg" alt="" />
                                                        <div className="uploadedItemBoxTitle"> درحال آپلود فایل... </div>
                                                        <div class="progress-area">
                                                            <div class="uploaded-area" style={{ width: ReportageResults.loadingProgress + "%" }}>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="uploadedItemBox w-100">
                                                            <div className="checkmarkSingle mx-auto"></div>
                                                            <div className="uploadedItemBoxTitle mt-2">
                                                                فایل با موفقیت آپلود شد؛
                                                            </div>
                                                            <div className="uploadedItemBoxText">
                                                                جهت جایگزینی ، فایل قبلی را پاک نمایید.
                                                            </div>

                                                            <div className="d-flex align-items-center justify-content-between gap-3 mt-3 w-100">
                                                                <div className="uploadedItemFileName">
                                                                    {typeof PublishData.reportageFile !== 'string' ?
                                                                        PublishData.reportageFile?.[0]?.name :
                                                                        `file.${PublishData.reportageFile.replace(storageUrl, "").split(".")[1]}`
                                                                    }
                                                                </div>
                                                                <div className="siteTempBtn whiteBg"
                                                                    onClick={e => handleRemoveUploadedReportageFile(e)}
                                                                >
                                                                    <div>حذف فایل</div>
                                                                    <img src="/assets/images/close-circle.svg" alt="" />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </>
                                                }




                                            </>
                                        }
                                    </label>


                                </div>

                            </div>
                        </div>

                        {PublishData.selectedReportage === -1 &&
                            <>
                                <div
                                    style={{
                                        width: "calc(100% - 70px)",
                                        marginRight: "auto"
                                    }}
                                >
                                    <SiteAlert
                                        status={"error"}
                                        showImage={true}
                                    >
                                        هنوز هیچ وبسایت را انتخاب نکرده اید ، یکی از رسانه هارا به جهت انتشار انتخاب نمایید.
                                    </SiteAlert>
                                </div>
                            </>
                        }



                        {PublishData.selectedReportage !== -1 &&
                            <>
                                <div id="step3" className="createReportageItemBox mt-3">
                                    <div className="createReportageItemStepBox">
                                        <div>مرحله</div>
                                        <div className="step">03</div>
                                    </div>
                                    <div className={"createReportageItemCard uploadReportageFileResults" + (!ReportageResults?.data?.wordCount ? " disabled" : "")}>

                                        <div className="uploadReportageFileResultsRightBox">
                                            <div className="text-blueColor fw-bold">
                                                جزئیات فایل
                                            </div>

                                            {PublishData.reportageFile === null ?
                                                <>
                                                    <div className="text-gray3 mt-3">
                                                        برای بررسی فایل شما لطفا رپورتاژ خود را آپلود کنید
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="uploadReportageFileResultsRightFileTitle">
                                                        <InputTemplate
                                                            title={"تیتر یا عنوان مقاله"}
                                                            value={PublishData.titleFile}
                                                            onChange={e => setPublishData({ ...PublishData, titleFile: e.target.value })}
                                                        />

                                                        <div className="siteTempBtn">
                                                            <img src="/assets/images/eye.svg" alt="" />
                                                            <div>پیش نمایش محتوا</div>
                                                        </div>

                                                    </div>
                                                    <div className="text-accentColor mt-2" style={{
                                                        fontSize: 11,
                                                    }}>
                                                        در صورتی که تیتر مقاله با تیتر مورد نظر شما متفاوت است آن را اصلاح کنید
                                                    </div>
                                                </>
                                            }



                                        </div>
                                        <div className="uploadReportageFileResultsLeftBox">

                                            <div className="resultReportageFileUploadedCard">
                                                <div className="resultReportageFileUploadedCardHeader">
                                                    <div
                                                        className={
                                                            "resultReportageFileUploadedCardSquare" +
                                                            (PublishData.selectedReportageObj?.reportage?.info?.max_word >= ReportageResults.data?.wordCount ? " success" : "") +
                                                            (PublishData.selectedReportageObj?.reportage?.info?.max_word < ReportageResults.data?.wordCount ? " error" : "")
                                                        }
                                                    >
                                                        {PublishData.selectedReportageObj?.reportage?.info?.max_word < ReportageResults.data?.wordCount &&
                                                            <>
                                                                <img src="/assets/images/combined-Shape.svg" alt="" />
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="resultReportageFileUploadedCardTitle">تعداد کلمات</div>
                                                </div>
                                                <div
                                                    className={
                                                        "resultReportageFileUploadedCardValue" +
                                                        (PublishData.selectedReportageObj?.reportage?.info?.max_word >= ReportageResults.data?.wordCount ? " text-success" : "") +
                                                        (PublishData.selectedReportageObj?.reportage?.info?.max_word < ReportageResults.data?.wordCount ? " text-danger" : "")
                                                    }
                                                >{PublishData.selectedReportageObj?.reportage?.info?.max_word || 0} / {ReportageResults.data?.wordCount || 0}</div>
                                            </div>

                                            <div className="resultReportageFileUploadedCard">
                                                <div className="resultReportageFileUploadedCardHeader">
                                                    <div
                                                        className={
                                                            "resultReportageFileUploadedCardSquare" +
                                                            (PublishData.selectedReportageObj?.reportage?.info?.follow_link_count >= ReportageResults.data?.linkCount ? " success" : "") +
                                                            (PublishData.selectedReportageObj?.reportage?.info?.follow_link_count < ReportageResults.data?.linkCount ? " error" : "")
                                                        }
                                                    >
                                                        {PublishData.selectedReportageObj?.reportage?.info?.follow_link_count < ReportageResults.data?.linkCount &&
                                                            <>
                                                                <img src="/assets/images/combined-Shape.svg" alt="" />
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="resultReportageFileUploadedCardTitle">تعداد لینک </div>
                                                </div>
                                                <div 
                                                    className={
                                                        "resultReportageFileUploadedCardValue" +
                                                        (PublishData.selectedReportageObj?.reportage?.info?.follow_link_count >= ReportageResults.data?.linkCount ? " text-success" : "") +
                                                        (PublishData.selectedReportageObj?.reportage?.info?.follow_link_count < ReportageResults.data?.linkCount ? " text-danger" : "")
                                                    }
                                                >{PublishData.selectedReportageObj?.reportage?.info?.follow_link_count || 0} / {ReportageResults.data?.linkCount || 0}</div>
                                            </div>

                                            <div className="resultReportageFileUploadedCard">
                                                <div className="resultReportageFileUploadedCardHeader">
                                                    <div
                                                        className={
                                                            "resultReportageFileUploadedCardSquare" +
                                                            (PublishData.selectedReportageObj?.reportage?.info?.image_count >= ReportageResults.data?.imageCount ? " success" : "") +
                                                            (PublishData.selectedReportageObj?.reportage?.info?.image_count < ReportageResults.data?.imageCount ? " error" : "")
                                                        }
                                                    >
                                                        {PublishData.selectedReportageObj?.reportage?.info?.image_count < ReportageResults.data?.imageCount &&
                                                            <>
                                                                <img src="/assets/images/combined-Shape.svg" alt="" />
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="resultReportageFileUploadedCardTitle">تعداد تصاویر </div>
                                                </div>
                                                <div 
                                                    className={
                                                        "resultReportageFileUploadedCardValue" +
                                                        (PublishData.selectedReportageObj?.reportage?.info?.image_count >= ReportageResults.data?.imageCount ? " text-success" : "") +
                                                        (PublishData.selectedReportageObj?.reportage?.info?.image_count < ReportageResults.data?.imageCount ? " text-danger" : "")
                                                    }
                                                >{PublishData.selectedReportageObj?.reportage?.info?.image_count || 0} / {ReportageResults.data?.imageCount || 0}</div>
                                            </div>


                                        </div>

                                    </div>
                                </div>

                                <div id="step4" className="createReportageItemBox">
                                    <div className="createReportageItemStepBox">
                                        <div>مرحله</div>
                                        <div className="step">04</div>
                                    </div>
                                    <div className={"d-flex justify-content-between gap-4 createReportageItemCard selectPublishTimeBox" + (!ReportageResults?.data?.wordCount ? " disabled" : "")}>

                                        <div className="selectPublishTimeRightBox">
                                            <div className="text-blueColor fw-bold">
                                                زمان انتشار
                                            </div>

                                            <div className="text-gray4 mt-3">
                                                زمان پیشنهادی انتشار
                                            </div>


                                            <div className="selectShowTimeReportageMainBox">
                                                <div className="selectShowTimeReportageBox">
                                                    <img src="/assets/images/calendar.svg" alt="" />
                                                    <DatePickerComponent
                                                        value={new Date(PublishData.publishTime)}
                                                        inputClassName={"datePickerInput"}
                                                        placeholder="زمان پیشنهادی انتشار"
                                                        onChange={value => setPublishData({ ...PublishData, publishTime: value })}
                                                        minDate={new Date().setDate(new Date().getDate() - 1)}
                                                    />
                                                </div>

                                                <div className="siteTempBtn justify-content-center mt-2" onClick={handleOpenDatePicker}>
                                                    <div>انتخاب از روی تقویم</div>
                                                </div>

                                            </div>




                                        </div>
                                        <div className="selectPublishTimeLeftBox">

                                            <ul>
                                                <li>
                                                    <div>
                                                        قابلیت ویرایش رپورتاژ فقط تایک روز قبل از تاریخ تعیین شده برای انتشار رپورتاژ وجود خواهد داشت.
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        انتشار رپورتاژ در تاریخ مشخص شده نیازمند تایید وب‌سایت نمایش‌دهنده رپورتاژ است.
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        رپورتاژهایی که بعد از ساعت 14 بارگذاری شوند ممکن است انتشار رپورتاژ به روز کاری بعد موکول شود .
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <span>در صورت انتخاب </span>
                                                        <span className="text-accentColor">پنجشنبه ، </span>
                                                        <span className="text-accentColor">جمعه </span>
                                                        <span>و یا </span>
                                                        <span className="text-accentColor">تعطیلات رسمی </span>
                                                        <span>، ممکن است روند انتشار شما با تاخیر انجام شود و یا به اولین روز کاری رسانه موکول گردد.</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        اگر لازم است رپورتاژ شما دقیقا در تاریخ اعلامی تان منتشر شود، حتما از قبل محتوا را جهت تایید رسانه از طریق تیکت ارسال بفرمایید
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        در صورت تایید رسانه رپورتاژ شما در تاریخ مشخص شده منتشر خواهد شد .
                                                    </div>
                                                </li>
                                            </ul>

                                        </div>

                                    </div>
                                </div>

                                <div id="step5" className="createReportageItemBox">
                                    <div className="createReportageItemStepBox">
                                        <div>مرحله</div>
                                        <div className="step">05</div>
                                    </div>
                                    <div className="createReportageItemCard selectPublishAdvanceDataBox">

                                        <div className="text-blueColor fw-bold">
                                            تگ های پیشنهادی
                                        </div>

                                        <TagsInput
                                            className="siteTempInput tagTempInput mt-2"
                                            inputProps={{ placeholder: "تگ های رپورتاژ" }}
                                            value={PublishData.tag}
                                            onChange={(tags) => setPublishData({ ...PublishData, tag: tags })}
                                        />
                                        <div className="text-gray4 mt-1"
                                            style={{
                                                fontSize: 11
                                            }}
                                        >
                                            با دکمه enter تگ هارا از هم جدا کنید
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between gap-4 mt-3">
                                            <div className="selectPublishAdvanceDataRightBox">

                                                <div className="text-blueColor fw-bold mb-2">
                                                    توضیحات به نمایش دهنده
                                                </div>

                                                <div className="position-relative">
                                                    <textarea
                                                        className="siteTempTextarea p-3"
                                                        rows="6"
                                                        value={PublishData.description}
                                                        onChange={e => setPublishData({ ...PublishData, description: e.target.value?.length < 501 ? e.target.value : e.target.value?.slice(0 , 500) })}
                                                    />
                                                    <div className="descriptionCountMainBox">
                                                        {PublishData.description?.length} / 500
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="uploadReportageFileLeftBox selectPublishAdvanceDataLeftBox">
                                                <div className="text-blueColor fw-bold mb-2">
                                                    فایل ضمیمه رپورتاژ
                                                </div>
                                                <label className="uploadReceiptInCartBox"
                                                    onDrop={(e) => handleDrop(e, "reportageAttachFile")}
                                                    onDragOver={handleDragOver}
                                                >
                                                    {PublishData.reportageAttachFile === null ?
                                                        <>
                                                            <img src="/assets/images/paper-upload.svg" alt="" />
                                                            <div className="uploadReceiptInCartHeader">
                                                                <div className="uploadReceiptInCartDesc">
                                                                    برای آپلود فایل رپورتاژ کلیک کنید
                                                                    یا فایل را در اینجا رها کنید
                                                                </div>
                                                            </div>
                                                            <input type="file" hidden accept=".doc , .docx"
                                                                onChange={e => setPublishData({ ...PublishData, reportageAttachFile: e.target.files })}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <div className="uploadedItemBox">
                                                                <div className="checkmarkSingle mx-auto"></div>
                                                                <div className="uploadedItemBoxTitle mt-2">
                                                                    فایل با موفقیت آپلود شد؛
                                                                </div>
                                                                <div className="uploadedItemBoxText">
                                                                    جهت جایگزینی ، فایل قبلی را پاک نمایید.
                                                                </div>

                                                                <div className="d-flex align-items-center justify-content-between gap-5 mt-3">
                                                                    <div className="uploadedItemFileName">
                                                                        {typeof PublishData.reportageAttachFile !== 'string' ?
                                                                            PublishData.reportageAttachFile?.[0]?.name :
                                                                            `file.${PublishData.reportageAttachFile.replace(storageUrl, "").split(".")[1]}`
                                                                        }
                                                                    </div>
                                                                    <div className="siteTempBtn whiteBg"
                                                                        onClick={e => handleRemoveUploadedReportageAttachFile(e)}
                                                                    >
                                                                        <div>حذف فایل</div>
                                                                        <img src="/assets/images/close-circle.svg" alt="" />
                                                                    </div>
                                                                </div>

                                                            </div>



                                                        </>
                                                    }
                                                </label>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>
                        }


                    </div>

                    {PublishData.selectedReportage !== -1 &&

                        <div className="createReportageFooterMainBox">
                            <LoadingButton
                                className={"submitBtnWithCheckmark"}
                                onClick={handleCreateReportage}
                                loading={CreateLoading}
                            >
                                <div className="checkmarkSingle"></div>
                                <div>ثبت رپورتاژ آگهی</div>
                            </LoadingButton>
                        </div>

                    }


                </div>

            </div>
        </>
    );
}

export default CreateReportagePage;